import _ from 'lodash'

function getAuthFailureErrorMessageForSendDeleteAccountLink(authType) {
  if (authType === 'password') {
    return 'Incorrect password. Please try again.'
  }
  return `Authentication failed. Please make sure to select the
     ${_.startCase(authType)} account tied to your plickers account.`
}

function getMessagesByStatus(requestName, data = {}) {
  //  example
  // <request name>: {
  //   <status code>: <message>,
  //   otherStatus: <message> // if not set, default will be used
  //   noResponse: <message> // if not set, default will be used
  // }
  const { email, authType } = data

  const messages = {
    signup: {
      400: 'Captcha verification failed. Please try again.',
      409: `An account for ${email} already exists`,
    },
    login: {
      400: 'Captcha verification failed. Please try again.',
      401: 'Please check that your email address and password are correct.',
      412: 'Password is not set up for this email. Please try other signin methods.',
      422: `We couldn’t find an account with the email ${email}`,
    },
    externalLogin: {
      401: 'The password you entered was incorrect',
      404: 'We couldn’t find an account with this sign-in',
    },
    forgotPassword: {
      404: `We couldn’t find an account with the email ${email}`,
    },
    resetPassword: {
      // cannot find user by user id
      404: 'Looks like a broken link was used. Please make sure the link is not modified.',
      409: 'Your new password cannot be the same as your old password.',
      422: 'This link is invalid or expired. Please generate a new link.',
    },
    updateEmail: {
      412: 'The password you entered was incorrect',
      409: 'An account with that email already exists',
      422: 'User has Google ID, email can not be updated', // should be blocked by UI
    },
    updatePassword: {
      409: 'Your new password cannot be the same as your old password.',
      412: 'The existing password you entered was incorrect',
    },
    addGoogleSignin: {
      409: 'The selected Google account is already linked to another Plickers account.',
      412: 'The password you entered was incorrect',
      422: 'The Google account email does not match your existing Plickers email. Please try again with a matching Google account.',
    },
    addAppleSignin: {
      409: 'This Apple account is already linked with another Plickers account.',
      412: 'The password you entered was incorrect',
      422: 'The Apple account email does not match your existing Plickers email. Please try again with a matching Apple account.',
    },
    removeGoogleSignin: {
      412: 'The password you entered was incorrect',
      422: 'Can\'t remove as Google is the only sign-in method available', // should be blocked by UI
    },
    removeAppleSignin: {
      412: 'The password you entered was incorrect',
      422: 'Can\'t remove as Apple is the only sign-in method available', // should be blocked by UI
    },
    updateGoogleSignin: {
      409: 'The selected Google account is already linked to another Plickers account',
      412: 'Authentication failed. Please make sure to authenticate with the Google account currently linked to your Plickers account.',
    },
    addPassword: {
      409: 'User already has password setup', // should be blocked by UI
      412: 'Authentication failed. Please make sure to use the account linked to your Plickers account.',
    },
    associateDataOwner: {},
    sendDeleteAccountLink: {
      412: getAuthFailureErrorMessageForSendDeleteAccountLink(authType),
    },
    getAccountData: {},
    deleteAccount: {},
  }
  return messages[requestName]
}

export default function getErrorMessageForRequest({ requestName, response, data }) {
  const messagesByStatus = getMessagesByStatus(requestName, data)

  let errorMessage
  if (response) {
    const { status, textString } = response

    // handle clientIdentity error first
    const handleClientIdentityError = ['login', 'externalLogin'].includes(requestName)
    if (handleClientIdentityError && textString?.includes('clientIdentity')) {
      if (status === 400) {
        return 'invalid clientIdentity token value provided'
      }
      if (status === 401) {
        return 'clientIdentity token is missing - cookie might be disabled'
      }
    }

    if (messagesByStatus[status]) {
      errorMessage = messagesByStatus[status]
    } else {
      errorMessage = messagesByStatus.otherStatus ||
        `Something went wrong. If the problem persists, contact support. (status: ${status})`
    }
  } else {
    errorMessage = messagesByStatus.noResponse ||
      'Cannot send request. Please check internet connection.'
  }

  return errorMessage
}
